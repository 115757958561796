
















































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import Placeholders from "@/mixins/placeholders/placeholders";
import { InputSetups } from "../../mixins/input-setups";
import CurrentModule from '@/components/Modules/components/CurrentModule.vue'
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'
import { ModuleTypesEnum } from '@/includes/logic/Modules/types/types'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    CurrentModule,
    CenteredColumnLayout,
  },
})
export default class ChatRules extends Mixins(UseFields, InputSetups, Placeholders, TempCurrentModuleMixin) {
  created() {
    this.setCurrentModule(ModuleTypesEnum.RulesCommandHandlerModule)
  }
}
